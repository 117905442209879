<template lang="">
	<ParentGraph
		:title="$t('formulaire')"
		class="flex-center"
		:show="errorImage"
	>
		<div
			class="position-relative  flex-center candTop"
			v-if="!$siteconf.hideImages?.application"
		>
			<img
				v-if="errorImage"
				src="/s/images/Calque_3.svg"
				class="position-absolute "
			>
			<img
				ref="Image"
				:src="imageSrc"
				class="position-relative ms-5"
			>
		</div>
	</ParentGraph>
</template>
<script>
import { imageErrorHandling} from "@/../public/js/imageErrorHandling"
export default {
	mixins: [imageErrorHandling],

	data() {
		return {
			errorImage: false,
			imageSrc: "/c/images/form",
			fallbackImageSrc: "/s/images/form.png"
		};
	},
	mounted() {
		// Check the image dimensions on mount
		this.preloadImage(this.imageSrc, this.fallbackImageSrc);
	}
	};
</script>
<style lang="scss" scoped>

</style>
